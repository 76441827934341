import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  pdf,
  Link,
} from "@react-pdf/renderer";
import LocTecIcon from "../../../../../../src/@crema/components/PdfDownload/images/icon.png";
import LocTecLogo from "../../../../../../src/@crema/components/PdfDownload/images/logo_white.png";
import AdsumLogo from "../../../../../../src/@crema/components/PdfDownload/images/AdsumLogo.jpeg";
import DefaultCarImg from "../../../../../../src/@crema/components/PdfDownload/images/CarImg.jpg";
import Sign from "../../../../../../src/@crema/components/PdfDownload/images/Sign.png";
import LocTecStamp from "../../../../../../src/@crema/components/PdfDownload/images/LocTec_stamp2.png";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    width: "100%",
    height: "100vh",
    padding: "0",
  },
  Header: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#FE3A10",
    width: "100%",
    padding: "10px 10px",
    position: "relative",
  },
  row: {
    flexDirection: "row",
    alignItems: "flex-start",
    width: "100%",
    justifyContent: "space-between",
  },
  brandDetails: {
    marginLeft: "2px",
    width: "40%",
  },
  brandTitle: {
    fontSize: 14,
    fontWeight: 600,
    color: "#ffffff",
    marginBottom: "4px",
  },
  description: {
    fontSize: 9,
    color: "#e0e0e0",
    padding: "1px 0px",
  },
  boldText1: {
    fontWeight: 800,
    fontSize: 10,
    color: "#ffffff",
  },
  contactInfo: {
    flexDirection: "column",
    marginLeft: "16px",
    width: "40%",
  },
  contactDetail: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "5px",
  },
  contactTitle: {
    width: "100px",
    color: "#ffffff",
    fontWeight: "bold",
    fontSize: 10,
  },
  logImg: {
    height: "20px",
    width: "85px",
    borderRadius: 20, 
    overflow: 'hidden',
  },
  section2: {
    backgroundColor: "#ede4f6",
    width: "100%",
    height: "10%",
    // padding: "10px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    color: "black",
  },
  MainFlexBox: {
    width: "100%",
  },
  flexMainBox: {
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    paddingLeft: "10px",
  },
  MainCarImg: {
    height: "100%",
    width: "200px",
    objectFit: "cover",
  },
  descriptions: {
    width: "25%",
    padding: "5px",
    fontSize: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  section3: {
    backgroundColor: "white",
    width: "100%",
    // height: "15%",
    display: "flex",
    flexDirection: "row",
  },

  largeBox: {
    width: "100%",
    padding: "2px 5px",
    backgroundColor: "white",
  },
  mainBlock: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    // marginBottom: "5px",
    padding: "1px",
    fontSize: 7,
    color: "black",
    textAlign: "center",
  },
  subBlock: {
    width: "30%",
    textAlign: "left",
    marginRight: '5px'
  },
  CenteText: {
    display: "flex",
    flexDirection: "row",
    textAlign: "left",
    alignItems: "flex-end",
    fontSize: 11,
    // marginBottom: "10px",
    padding: "2px",
    color: "gray",
  },
  letterStyle: {
    fontStyle: "italic",
    fontSize: 12,
    color: 'black'
  },
  BoldTitles: {
    fontSize: 14,
    color: "black",
    marginRight: "10px",
  },
  section4: {
    backgroundColor: "#ffffff",
    width: "100%",
    padding: "0px 10px",
    flexDirection: "row",
    gap: "10px",
    alignItems: "flex-start",
  },
  caseBox: {
    padding: "0 10px",
    borderRadius: "10px",
    border: "1px solid lightgray",
  },
  mainBox: {
    width: "50%",
    marginTop: '5px',
    color: "black",
    fontSize: 10,
  },
  subTitle: {
    backgroundColor: "#44008f",
    color: "white",
    padding: "5px",
    fontSize: 9,
    textAlign: "center",
    borderRadius: '10px'
  },
  subBox: {
    border: "1px solid lightgray",
    borderRadius: "10px",
    margin: "1px 0px",
    overflow: "hidden",
  },
  subMenuData: {
    padding: "2px 0px",
    letterSpacing: ".3px",
    fontSize: 9,
  },
  subMenuTitle: {
    padding: "2px 0px",
    color: "gray",
    letterSpacing: ".3px",
    fontSize: 10,
  },
  shareDownload: {
    flexDirection: "column",
    alignItems: "center",
    padding: "5px",
  },
  page2: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#f0f0f0",
    width: "100%",
    height: "100vh",
  },
  imageWrapper: {
    width: "50%",
    height: "17%",
    padding: "5px",
    border: ".5px solid #dddddd",
  },
  Section5: {
    backgroundColor: "#ffffff",
    width: "100%",
    height: "20%",
    padding: "10px",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  Box1: {
    width: "65%",
    padding: "10px",
    borderRadius: "10px",
    border: "1px solid lightgray",
  },
  normalText: {
    fontSize: 12,
    color: "black",
    margin: "5px 0px",
    fontWeight: 700
  },
  normalText5: {
    fontSize: 10,
    color: "gray",
    letterSpacing: ".3px",
    lineHeight: "1.4px",
  },
  normalText6: {
    fontSize: 12,
    color: "black",
    // width: "50%",
  },
  Box2: {
    width: "35%",
    height: "100%",
    padding: "10px",
  },
  signatureImage: {
    width: "100px",
    height: "100px",
    objectFit: "contain",
  },
  pageImage: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  menuItem: {
    flexDirection: "row",
    // justifyContent: "space-between",
    alignItems: "center",
    gap: 10,
    padding: 5,
    // margin: "2px 0px",
    // marginBottom: 3,
    borderBottomWidth: 0.5,
    borderBottomColor: 'gray',
    borderBottomStyle: 'solid',
  },
  label: {
    width: '50%',
    fontSize: 10,
    flexShrink: 0,
  },
  value: {
    width: '50%',
    fontSize: 10,
    flexShrink: 0,
    color: 'gray'
  },
  GrayText: {
    color: "gray",
    fontSize: 9,
    padding: "2px 0px",
  },
  specialGrayText: {
    color: "black",
    fontSize: 9,
    padding: "2px 0px",
    marginBottom: "5px",
  },
  footer: {
    backgroundColor: "#44008f",
    padding: "10px",
    flexDirection: "row",
    justifyContent: "space-around",
    // marginTop: "10px",
    position: "absolute",
    bottom: 0,
    width: "100%",
  },
  normalText7: {
    fontSize: 10,
    color: "white",
  },
});

export const PdfDocument = ({ user, generatedAt }) => {
  // console.log("images",user.images);

  const {
    agent,
    agentid,
    agentname,
    name,
    number,
    email,
    vehicleName,
    client,
    qcName,
    executiveName,
  } = user || {};

  return (
    <Document>
      <Page size={{ width: 595.276, height: 1080 }} style={styles.page}>
        <View style={styles.Header}>
          <View style={styles.row}>
            {/* <Image src={LogoImg} style={styles.logo} /> */}
            <View style={styles.brandDetails}>
              {/* <Text style={styles.brandTitle}>Smart AI Inspection</Text> */}
              <Image src={LocTecLogo} style={styles.logImg} />
              <Text style={styles.description}>
                Unit No-11A, Office No. 411, 4th Floor
              </Text>
              <Text style={styles.description}>Amsri Square Next to Apollo Hospital</Text>
              <Text style={styles.description}>Hyderabad-500003, T S</Text>
            </View>
            <View style={styles.contactInfo}>
              <View style={styles.contactDetail}>
                <Text style={styles.contactTitle}>Phone Number:</Text>
                <Text style={styles.boldText1}>022-69719073</Text>
              </View>
              <View style={styles.contactDetail}>
                <Text style={styles.contactTitle}>Email:</Text>
                <Text style={styles.boldText1}>info@smartinspect.ai</Text>
              </View>
              <View style={styles.contactDetail}>
                <Image src={AdsumLogo} style={styles.logImg} />
              </View>
            </View>
          </View>
        </View>

        <View style={styles.section2}>
          <View style={styles.MainFlexBox}>
            <View style={styles.flexMainBox}>
              <View style={styles.descriptions}>
                <Text style={styles.subMenuTitle}>Client</Text>
                <Text style={styles.subMenuData}>
                  RESILIENT DIGI SERVICES PRIVATE LIMITED
                </Text>
              </View>
              <View style={styles.descriptions}>
                <Text style={styles.subMenuTitle}>Case Id</Text>
                <Text style={styles.subMenuData}>Test Case Id</Text>
              </View>
              <View style={styles.descriptions}>
                <Text style={styles.subMenuTitle}>Verification Type</Text>
                <Text style={styles.subMenuData}>Test Verification Type</Text>
              </View>
              <View style={styles.descriptions}>
                <Text style={styles.subMenuTitle}>Product</Text>
                <Text style={styles.subMenuData}>Test Product</Text>
              </View>
            </View>
            <View style={styles.flexMainBox}>
              <View style={styles.descriptions}>
                <Text style={styles.subMenuTitle}>Client Branch</Text>
                <Text style={styles.subMenuData}>Test Client Branch</Text>
              </View>
              <View style={styles.descriptions}>
                <Text style={styles.subMenuTitle}>Location</Text>
                <Text style={styles.subMenuData}>Test Location</Text>
              </View>
              <View style={styles.descriptions}>
                <Text style={styles.subMenuTitle}>Address</Text>
                <Text style={styles.subMenuData}>161, Kamna society main road, Vaishali secter 5,Near Ramlila graund, Ghaziabad, Uttar Pradesh -201010</Text>
              </View>
              <View style={styles.descriptions}>
                <Text style={styles.subMenuTitle}>Mobile</Text>
                <Text style={styles.subMenuData}>+91 9876543210</Text>
              </View>
            </View>
          </View>
          {/* <Image
            // src="https://feeds.abplive.com/onecms/images/uploaded-images/2023/10/17/f2477a8b2c611a0f92fa7d3ac11d9ed81697514430233320_original.jpg?impolicy=abp_cdn&imwidth=640"
            src={user.images.length > 0 ? user.images[0] : "https://feeds.abplive.com/onecms/images/uploaded-images/2023/10/17/f2477a8b2c611a0f92fa7d3ac11d9ed81697514430233320_original.jpg?impolicy=abp_cdn&imwidth=640"}
            style={styles.MainCarImg}
          /> */}
        </View>

        <View style={styles.section3}>
          <View style={styles.largeBox}>
            <View style={styles.CenteText}>
              <Text style={styles.BoldTitles}>NARAYAN Hari amritsari naan</Text>
              <Text style={styles.letterStyle}>
                Status: NEGATIVE
              </Text>
            </View>
            <View style={styles.mainBlock}>
              <View style={styles.subBlock}>
                <Text style={styles.GrayText}>Company Name</Text>
                <Text style={styles.specialGrayText}>Test Company</Text>
              </View>
              <View style={styles.subBlock}>
                <Text style={styles.GrayText}>Occupation</Text>
                <Text style={styles.specialGrayText}>Test Occupation</Text>
              </View>
              <View style={styles.subBlock}>
                <Text style={styles.GrayText}>Verifier Emp Id</Text>
                <Text style={styles.specialGrayText}>
                  Test Verifier Emp Id
                </Text>
              </View>
              <View style={styles.subBlock}>
                <Text style={styles.GrayText}>Back Office Emp Id</Text>
                <Text style={styles.specialGrayText}>
                  Test Back Office Emp Id
                </Text>
              </View>
            </View>
            <View style={styles.mainBlock}>
              <View style={styles.subBlock}>
                <Text style={styles.GrayText}>OCL Range</Text>
                <Text style={styles.specialGrayText}>Test OCL Range</Text>
              </View>
              <View style={styles.subBlock}>
                <Text style={styles.GrayText}>Sub Status</Text>
                <Text style={styles.specialGrayText}>Test Sub Status</Text>
              </View>
              <View style={styles.subBlock}>
                <Text style={styles.GrayText}>Time of Allocation</Text>
                <Text style={styles.specialGrayText}>13:55</Text>
              </View>
              <View style={styles.subBlock}>
                <Text style={styles.GrayText}>Date of Allocation</Text>
                <Text style={styles.specialGrayText}>21-12-1900</Text>
              </View>
            </View>
            <View style={styles.mainBlock}>
              <View style={styles.subBlock}>
                <Text style={styles.GrayText}>Date of Report</Text>
                <Text style={styles.specialGrayText}>23-12-1900</Text>
              </View>
              <View style={styles.subBlock}>
                <Text style={styles.GrayText}>Time of Report</Text>
                <Text style={styles.specialGrayText}>
                  15:40
                </Text>
              </View>
              <View style={styles.subBlock}>
                <Text style={styles.GrayText}>Submitted Loc</Text>
                <Text style={styles.specialGrayText}>
                  Test Hyderabad
                </Text>
              </View>
              <View style={styles.subBlock}>
                <Text style={styles.GrayText}>TAT Met</Text>
                <Text style={styles.specialGrayText}>Test TAT</Text>
              </View>
            </View>
          </View>
        </View>

        <Text style={styles.subTitle}>OTHER INFORMATION</Text>
        <View style={styles.section4}>
          <View style={styles.mainBox}>
            <View style={styles.subBox}>
              {/* <Text style={styles.subTitle}>OTHER INFORMATION</Text> */}
              <View style={styles.menuItem}>
                <Text style={styles.label}>Designation of Person Met</Text>
                <Text style={styles.value}>
                  Test Designation of Person Met
                </Text>
              </View>
              <View style={styles.menuItem}>
                <Text style={styles.label}>Exact Company Name</Text>
                <Text style={styles.value}>NA</Text>
              </View>
              <View style={styles.menuItem}>
                <Text style={styles.label}>Exact Designation of Applicant</Text>
                <Text style={styles.value}>
                  NA
                </Text>
              </View>
              <View style={styles.menuItem}>
                <Text style={styles.label}>Exact Office / Business Address </Text>
                <Text style={styles.value}>NA</Text>
              </View>
              <View style={styles.menuItem}>
                <Text style={styles.label}>Is Business premesis Owned or Rented</Text>
                <Text style={styles.value}>ON LEASE</Text>
              </View>
              <View style={styles.menuItem}>
                <Text style={styles.label}>Locality / Surrounding Area</Text>
                <Text style={styles.value}>
                  MIDDLE CLASS
                </Text>
              </View>
              <View style={styles.menuItem}>
                <Text style={styles.label}>Location Visited (with Landmark)</Text>
                <Text style={styles.value}>
                  NA
                </Text>
              </View>
              <View style={styles.menuItem}>
                <Text style={styles.label}>Main Business of Company</Text>
                <Text style={styles.value}>YES</Text>
              </View>
              <View style={styles.menuItem}>
                <Text style={styles.label}>Name of Neighbour 1 </Text>
                <Text style={styles.value}>FAMILY MEMBER</Text>
              </View>
              <View style={styles.menuItem}>
                <Text style={styles.label}>Name of Neighbour 2</Text>
                <Text style={styles.value}>
                  NA
                </Text>
              </View>
              <View style={styles.menuItem}>
                <Text style={styles.label}>Name Plate / Business Board Type</Text>
                <Text style={styles.value}>NA</Text>
              </View>
              <View style={styles.menuItem}>
                <Text style={styles.label}>Nature / Type of Job</Text>
                <Text style={styles.value}>
                  NA
                </Text>
              </View>
              <View style={styles.menuItem}>
                <Text style={styles.label}>Nature of Business / Industry</Text>
                <Text style={styles.value}>
                  NA
                </Text>
              </View>
              <View style={styles.menuItem}>
                <Text style={styles.label}>Neighbour 1 Status </Text>
                <Text style={styles.value}>NA</Text>
              </View>
              
              <View style={styles.menuItem}>
                <Text style={styles.label}>Neighbour 2 Status</Text>
                <Text style={styles.value}>NA</Text>
              </View>
            </View>

          </View>
          <View style={styles.mainBox}>
            <View style={styles.subBox}>
              {/* <Text style={styles.subTitle}>OTHER INFORMATION</Text> */}
              <View style={styles.menuItem}>
                <Text style={styles.label}>No of Employees Sighted</Text>
                <Text style={styles.value}>
                  NA
                </Text>
              </View>
              <View style={styles.menuItem}>
                <Text style={styles.label}>Number of Years in Service</Text>
                <Text style={styles.value}>
                  NA
                </Text>
              </View>
              <View style={styles.menuItem}>
                <Text style={styles.label}>Occupation / Source of Income</Text>
                <Text style={styles.value}>
                  NA
                </Text>
              </View>
              <View style={styles.menuItem}>
                <Text style={styles.label}>Person Met During the Visit</Text>
                <Text style={styles.value}>SITA DEVI WIFE</Text>
              </View>
              <View style={styles.menuItem}>
                <Text style={styles.label}>Person Met at Office Telephone Confirms that Applicant Worked at Given Address</Text>
                <Text style={styles.value}>NA</Text>
              </View>
              <View style={styles.menuItem}>
                <Text style={styles.label}>Type of Business Activity</Text>
                <Text style={styles.value}>
                  NA
                </Text>
              </View>
              <View style={styles.menuItem}>
                <Text style={styles.label}>Type of Company</Text>
                <Text style={styles.value}>
                  NA
                </Text>
              </View>
              <View style={styles.menuItem}>
                <Text style={styles.label}>Value of No Stock Sighted</Text>
                <Text style={styles.value}>
                  NA
                </Text>
              </View>
              <View style={styles.menuItem}>
                <Text style={styles.label}>Visiting Cards Obtained as Proof of Visit</Text>
                <Text style={styles.value}>YES</Text>
              </View>
              <View style={styles.menuItem}>
                <Text style={styles.label}>Accessibility/Ease of Locating Address/Condition of Approach Road</Text>
                <Text style={styles.value}>
                  EASY
                </Text>
              </View>
              <View style={styles.menuItem}>
                <Text style={styles.label}>Address Confirmed?</Text>
                <Text style={styles.value}>YES</Text>
              </View>
              <View style={styles.menuItem}>
                <Text style={styles.label}>Business Category</Text>
                <Text style={styles.value}>
                  NA
                </Text>
              </View>
              <View style={styles.menuItem}>
                <Text style={styles.label}>Carpet Area / Sq.Ft.</Text>
                <Text style={styles.value}>
                  NA
                </Text>
              </View>
              <View style={styles.menuItem}>
                <Text style={styles.label}>Type Of Roof Of The Resi / Off</Text>
                <Text style={styles.value}>NA</Text>
              </View>
              <View style={styles.menuItem}>
                <Text style={styles.label}>Note</Text>
                <Text style={styles.value}>NA</Text>
              </View>
              {/* <View style={styles.menuItem}>
                <Text style={styles.label}>Rear Rim </Text>
                <Text style={styles.value}>NA</Text>
              </View> */}
            </View>
            
          </View>
          
        </View>

        <View style={styles.Section5}>

          <View style={styles.Box1}>
            <Text style={styles.normalText}>Comments:</Text>
            <Text style={styles.normalText5}>
              VISIT DONE AT GIVEN ADDRESS MET WITH WIFE SITA DEVI SHE CONFIRMED THAT GIVEN ADDRESS
              BELONGS TO APPLICANT RESIDENCE ONLY NO SUCH OFFICE EXIST HERE APPLICANT HAVING
              OFFICE AT SECTOR 5 VAISHALI AND HE IS ROAD SIDE VENDOR ONLY GIVEN ADDRESS IS NO SUCH
              OFFICE EXIST MIDDLE LOCALITY EASY TO LOCATE AND RESIDENTIAL AREA TPC ONLY FAMILY
              RESIDING CONFIRM OFFICE DETAILS BY CONFIRMED
            </Text>
          </View>

          <View style={styles.Box2}>
            <View style={styles.shareDownload}>
              <Text style={styles.normalText6} >Authorized Signature:</Text>
            </View>
            <View style={styles.shareDownload}>
              <Image src={LocTecStamp} style={styles.signatureImage} />
            </View>
          </View>
        </View>

        <View style={styles.footer}>
          <Text style={styles.normalText7}>Executive Name: {executiveName || 'NA'}</Text>
          <Text style={styles.normalText7}>
            Report Generated At: {generatedAt.date} {generatedAt.time}
          </Text>
          <Text style={styles.normalText7}>QC Done by: {qcName || 'NA'}</Text>
        </View>

      </Page>

      <Page size={{ width: 595.276, height: 1080 }} style={styles.page2}>
      <View style={styles.Header}>
          <View style={styles.row}>
            {/* <Image src={LogoImg} style={styles.logo} /> */}
            <View style={styles.brandDetails}> 
              {/* <Text style={styles.brandTitle}>Smart AI Inspection</Text> */}
              <Image src={LocTecLogo} style={styles.logImg} />
              <Text style={styles.description}>
                Unit No-11A, Office No. 411, 4th Floor
              </Text>
              <Text style={styles.description}>Amsri Square Next to Apollo Hospital</Text>
              <Text style={styles.description}>Hyderabad-500003, T S</Text>
            </View>
            <View style={styles.contactInfo}>
              <View style={styles.contactDetail}>
                <Text style={styles.contactTitle}>Phone Number:</Text>
                <Text style={styles.boldText1}>022-69719073</Text>
              </View>
              <View style={styles.contactDetail}>
                <Text style={styles.contactTitle}>Email:</Text>
                <Text style={styles.boldText1}>info@smartinspect.ai</Text>
              </View>
              <View style={styles.contactDetail}>
                <Image src={AdsumLogo} style={styles.logImg} />
              </View>
            </View>
          </View>
        </View>

        {
          user?.imageUrlsAi.length > 0 ? (
            user?.imageUrlsAi.map((image, index) => (
              <View style={styles.imageWrapper} key={index}>
                <Link src={image}>
                  <Image src={image} style={styles.pageImage} />
                  
                </Link>
              </View>
            ))
          ) : (
            user?.images.map((image, index) => (
              <View style={styles.imageWrapper} key={index}>
                <Link src={image}>
                  <Image src={image} style={styles.pageImage} />
                  
                </Link>
              </View>
            ))
          )
        }
        {/* <View>
          <Image src={Stamp2} style={styles.signatureImage} />
          <Text style={styles.normalText6} >Authorized Signature:</Text>
        </View> */}
        {/* <ImageGallery images={user?.images} /> */}

        <View style={styles.footer}>
          <Text style={styles.normalText7}>Executive Name: {executiveName || 'NA'}</Text>
          <Text style={styles.normalText7}>
            Report Generated At: {generatedAt.date} {generatedAt.time}
          </Text>
          <Text style={styles.normalText7}>QC Done by: {qcName || 'NA'}</Text>
        </View>
      </Page>
    </Document>
  );
};

const generatePdfCopy = async (data: any) => {
  try {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');

    // Format the date as YYYY-MM-DD
    const formattedDate = `${year}-${month}-${day}`;
    const formattedTime = now.toLocaleTimeString();
    const blob = await pdf(<PdfDocument user={data} generatedAt={{ date: formattedDate, time: formattedTime }} />).toBlob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${data.caseId}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);

  } catch (error) {
    console.error("Error generating PDF:", error);
  }
};

export default generatePdfCopy;
