import DynamicForm from 'modules/ecommerce/Admin/Component/FormSubmission/DynamicForm';
import InstaValuerForm from 'modules/ecommerce/Admin/Component/FormSubmission/InstaValuer';
import PaymentPage from 'modules/ecommerce/Admin/Component/FormSubmission/Payment';
import SmartInspectForm from 'modules/ecommerce/Admin/Component/FormSubmission/SmartInspectForm';
import CreateCase2 from 'modules/ecommerce/Admin/createCase2';
import ClaimEvaluation from 'modules/ecommerce/Verifications/ClaimEvaluation';
import Verification from 'modules/ecommerce/Verifications/SelfEvaluation';
import VideoRecording from 'modules/ecommerce/VideoRecordings/VideoRecording';
import { element } from 'prop-types';
import React from 'react';

const Signin = React.lazy(() => import('../../../modules/auth/Signin'));
const Signup = React.lazy(() => import('../../../modules/auth/Signup'));
const ForgotPassword = React.lazy(
  () => import('../../../modules/auth/ForgetPassword/ForgetPasswordJwtAuth'),
);
const ConfirmSignupAwsCognito = React.lazy(
  () => import('../../../modules/auth/Signup/ConfirmSignupAwsCognito'),
);
const ResetPasswordAwsCognito = React.lazy(
  () => import('../../../modules/auth/ForgetPassword/ResetPasswordAwsCognito'),
);
export const authRouteConfig = [
  {
    path: '/signin',
    element: <Signin />,
  },
  {
    path: '/signup',
    element: <Signup />,
  },
  {
    path: '/forget-password',
    element: <ForgotPassword />,
  },
  {
    path: '/confirm-signup',
    element: <ConfirmSignupAwsCognito />,
  },
  {
    path: '/reset-password',
    element: <ResetPasswordAwsCognito />,
  },
  {
    path: '/verification/:id', // Dynamic public route
    element: <Verification />, // Component that handles verification
  },
  {
    path: '/claim/:id', // Dynamic public route
    element: <ClaimEvaluation />, // Component that handles verification
  },
  {
    path: '/video-recording/:id', // Dynamic public route
    element: <VideoRecording />, // Component that handles verification
  },
  {
    path: '/createCase',
    element: <CreateCase2 />
  },
  {
    path: '/form',
    element: <DynamicForm />
  },
  {
    path: '/smart-inspect',
    element: <SmartInspectForm />
  },
  {
    path: '/insta-valuer',
    element: <InstaValuerForm />
  },
  {
    path: '/payment',
    element: <PaymentPage />
  }
];

export default authRouteConfig;