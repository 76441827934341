import React, { useEffect, useMemo, useState } from 'react';
import AppsContainer from '@crema/components/AppsContainer';
import { useIntl } from 'react-intl';
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, MenuItem, Select } from '@mui/material';
import AppsHeader from '@crema/components/AppsContainer/AppsHeader';
import AppsContent from '@crema/components/AppsContainer/AppsContent';
import AppsPagination from '@crema/components/AppsPagination';
import Box from '@mui/material/Box';
import AppInfoView from '@crema/components/AppInfoView';
import AppSearchBar from '@crema/components/AppSearchBar';
import { useAppDispatch, useAppSelector } from '../../../toolkit/hooks';
import { getCustomers } from '../../../toolkit/actions';
import jwtAxios, { setAuthToken } from "../../../@crema/services/auth/jwt-auth";
import { openSnackbar } from "../../../toolkit/actions/snackbar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ContactDetail from "../../apps/Contact/ContactDetail";
import { Button, Hidden, TableRow } from '@mui/material';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import axios from 'axios';
// import { downloadPdf } from '@crema/helpers/FileHelper';
import generatePdf from 'modules/ecommerce/Admin/Component/Car/CarPdfDownload';
import dayjs from 'dayjs';
import DownloadIcon from '@mui/icons-material/Download';
import generatePdfTwoWheeler from './Component/TwoWheeler/TwoWheelerPdfDownload';
import generatePdfFarmEquipment from './Component/FarmEquipment/FarmEquipmentPdfDownload';
import generatePdfThreeWheeler from './Component/ThreeWheeler/ThreeWheelerPdfDownload';
import generatePdfCommercialVehicle from './Component/CommercialVehicle/CommercialVehiclePdfDownload';
import generatePdfConstructionEquipment from './Component/ConstructionEquipment/ConstructionEquipmentPdfDownload';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CarDiagram from './Component/Car/CarDiagram';
import uploadFile from 's3/s3.upload';
import generatePdfCopy from './Component/Car/CarPdfDownloadCopy';


const CaseReport = () => {
  const phase = "all";
  const { messages } = useIntl();
  const dispatch = useAppDispatch();
  const [customerCount, setCustomerCount] = useState(60)
  const [page, setPage] = useState(0);
  const [loadingTable, setLoadingTable] = useState(false)
  const [isShowDetail, onShowDetail] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [videoAvailable, setVideoAvailable] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);

  const sellers = searchParams.get('seller');
  const report = !!searchParams.get('report');

  const [selectedContact, setSelectedContact] = useState(
    {}
  );
  const [search, setSearch] = useState("");
  const onViewContactDetail = (contact) => {
    setSelectedContact(contact);
    onShowDetail(true);
  };
  const [diagramData, setDiagramData] = useState(null);
  const [isDiagramReady, setIsDiagramReady] = useState(false);


  const [customerList, setCustomerList] = useState([])
  const [customerCSV, setCustomerCSV] = useState(null)
  const customers = useMemo(() => {
    if (customerList.length > 0) {
      return customerList
        .filter(customer => customer.firstName.toLowerCase().includes(search.toLowerCase()))
        .map(customer => {
          let customerDetails = {}
          if (report) {
            customerDetails = {
              "Credential ID": customer.credentialID,
              "Name": `${customer.firstName} ${customer.lastName}`,
              "Status": `<span style="color: ${customer.status === "active" ? 'blue' : 'red'}">${customer.status}</span>`,
              "Email": customer.email,
              "Company Name": customer.companyName,
              "State": customer.state,
              "City": customer.city,
              "Zip Code": customer.zipCode,
              "Address": customer.address,
              "Phone Number": customer.phoneNumber,
              "Employer ID": customer.employerId,
              "Seller Permit": customer.sellerPermit,
              "Hear About Us": customer.hearAboutUs,
              "Selling Platform": customer.sellingPlatform.join(", "),
            };
          } else {
            customerDetails = {
              "Name": `${customer.firstName} ${customer.lastName}`,
              "Credits": customer.credits,
              "Status": `<span style="color: ${customer.status === "active" ? 'blue' : 'red'}">${customer.status}</span>`,
              "Email": customer.email,
              "Company Name": customer.companyName,
              "Phone Number": customer.phoneNumber,
              "Seller Permit": customer.sellerPermit,
            };
          }
          return customerDetails;
        });
    } else {
      return [];
    }
  }, [customerList, search]);


  const navigate = useNavigate()

  useEffect(() => {
    fetchData();
  }, []);


  const handleDownloadVideo = async (id) => {

    const exists = await checkVideoExists(id);
    
    if(exists) {
      const s3BucketUrl = 'https://smartinspection.s3.ap-south-1.amazonaws.com/';
      const videoUrl = `${s3BucketUrl}images/${id}/annotated_${id}.mp4`;
    
      // Create an anchor element and simulate a click to start the download
      const link = document.createElement('a');
      link.href = videoUrl;
      link.target = '_blank';
      link.download = `annotated_${id}.mp4`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    else {
      // alert('Annotated video not available.');
      toast.error('Annotated video not available.');
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await jwtAxios.post('api/report/verifedCase');
      const completedCases = response.data;
      // const sortedCases = completedCases.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setTableData(completedCases);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const updateImageUrlAiModel = async (id, aiImages = [], vehicleStatus = {}) => {
    // console.log("check images length", aiImages.length)
    if(aiImages.length < 1){
      // console.log("Image Detection Failed");
      toast.error('Unable to detect any images!');
    }
    try {
      const formData = {
        imageUrlsAi: aiImages,
        partsResponseAi: vehicleStatus,
      };
  
      await jwtAxios.put(`/api/case/${id}`, formData);
      // console.log('ImageUrlAi model updated successfully');
      toast.success('Image Detect Completed successfully');
    } catch (error) {
      console.error('Error updating ImageUrlAi model:', error);
    }
  };

  const fetchAiImages = async (data) => {
    // const url = "https://inartigence.com/aiengine";
    const url = "https://www.inartigence.com/aiengine";
  
    if(data?.bodyType !== "" && data?.make !== "" && data?.model !== "" && data?.variant!== ""){
      const requestData = {
        caseId: data?._id,
        bodyType: data?.bodyType,
        make: data?.make,
        model: data?.model,
        variant: data?.variant,
        images: data?.images, // Passing images as an array
      };
    
      try {
        const response = await axios.post(url, requestData);
        if (response.data && response.data.image_urls) {
          // console.log(response.data, "Imagessss");
  
          const aiImages = response.data.image_urls;
  
          const vehicleStatus = response.data.response || {};
  
          await updateImageUrlAiModel(data?._id, aiImages, vehicleStatus);
  
          // return response.data;
        } else {
          console.log("Error: No image URLs received.");
        }
      } catch (err) {
        console.error("Error fetching the data: " + err.message);
      }
    } else {
      toast.error('Invalid body type! Some of the data is missing');
    }
  };

  // const fetchData = () => {
  //   let config = {
  //     method: 'post',
  //     url: 'https://smartinspectai.justthinktech.in/smartinspectionai/v1/api/report/verifedCase', // deployed
  //     headers: {
  //       'apikey': 'c747b51cc1b18e6e9f4fdd72d6ada6c8',
  //       'Authorization': 'Bearer ' + localStorage.getItem('token')
  //     }
  //   };

  //   axios.request(config)
  //     .then((response) => {
  //       const completedCases = response.data;
  //       const sortedCases = completedCases.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  //       setTableData(sortedCases)
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }

  const checkVideoExists = async (id) => {
    //// .... start ... ////

    const s3BucketUrl = `https://smartinspection.s3.ap-south-1.amazonaws.com/images/${id}/annotated_${id}.mp4`;
  
    try {
      await axios.head(s3BucketUrl);
      return true; // Video exists
    } catch (error) {
      return false; // Video does not exist
    }

    // ..... end ..... ///
  };

  useEffect(() => {
    const checkAllVideos = async () => {
      const availability = {};

      for (const caseItem of tableData) {
        const exists = await checkVideoExists(caseItem._id);
        availability[caseItem._id] = exists;
      }

      setVideoAvailable(availability);
    };

    checkAllVideos();
  }, [tableData]);
  

  const showCustomer = (data, edit) => {
    const email = data.Email
    const customerToShow = customerList.filter(cus => cus.email === email);
    console.log(customerToShow[0])
    if (report) {
      onViewContactDetail(customerToShow[0]);
    } else {
      const goUrl = edit ? '/edituser' : '/user'

      navigate(goUrl, {
        state: {
          contact: customerToShow
        }
      })
    }
  }
  const onPageChange = (event, value) => {
    setPage(value);
  };
const onRowsPerPageChange = (event) => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
}

  // useEffect(() => {
  //   // dispatch(getCustomers(search, page));
  // }, [dispatch, search, page]);

  const onSearchCustomer = (e) => {
    setSearch(e.target.value);
    setPage(0);
  };


  const delete_fun = (data) => {
    console.log(data)

    jwtAxios.put('user', { credentialID: data["_id"], status: "not approved" }).then((response) => {
      console.log("done not approve", response.data)
    });
    onShowDetail(false);
    dispatch(openSnackbar('Application denied of user: ' + data.firstName, 'success'));
    // updateTable()
  }

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  const handleConfirmDelete = (data) => {
    setUserToDelete(data);
    setOpenConfirmDialog(true);
  };

  const handleDelete = () => {
    const id = userToDelete.Email;
    console.log(customerList)
    const customerToShow = customerList.filter(cus => cus.email === id);
    if (userToDelete) {
      jwtAxios.delete('user/' + customerToShow[0]["_id"]).then((response) => {
        console.log("done not approve", response.data);
        onShowDetail(false);
        dispatch(openSnackbar('Application denied of user: ' + userToDelete.Name, 'success'));
        // updateTable();
      });
    }
    setOpenConfirmDialog(false);
    setUserToDelete(null);
  };

  const handleCloseDialog = () => {
    setOpenConfirmDialog(false);
    setUserToDelete(null);
  };

  const edit_fun = (data, edit = false) => {
    const id = data.Email;
    console.log(customerList)
    const customerToShow = customerList.filter(cus => cus.email === id);
    console.log(customerToShow[0])
    if (report) {
      onViewContactDetail(customerToShow[0]);
    } else {
      const goUrl = edit ? '/edituser' : '/user'

      navigate(goUrl, {
        state: {
          contact: customerToShow
        }
      })
    }
  }

  const handleCompleteManually = (data) => {
    if(data.caseId) {
      navigate(`/smartinspectai/manualForm/${data.caseId}`, { state: 
        { 
          caseData: data,
          userDetails: {
            name: data.name,
            email: data.email,
            number: data.number,
            vehicleName: data.vehicleName,
            color: data.color,
            licensePlate: data.licensePlate,
            address: data.address,
            make: data.make,
            model: data.model,
            variant: data.variant,
            year: data.year,
            chasis: data.chasis,
            fuelUsed: data.fuelUsed,
            engineNumber: data.engineNumber,
            odometerType: data.odometerType,
            images: data.images,
            video: data.video,
            cusSign: data.cusSign,
            executiveName: data.executiveName,
            insurer: data.insurer,
            inspectorName: data.inspectorName,
            purpose: data.purpose,
            proposer: data.proposer,
            requestor: data.requestor,
            qcName: data.qcName,
            remarks: data.remarks,
            frontTyreRhs: data.frontTyreRhs,
            frontTyreLhs: data.frontTyreLhs,
            rearTyreRhs: data.rearTyreRhs,
            rearTyreLhs: data.rearTyreLhs,
            insuranceCompany: data.insuranceCompany,
            insuranceUpto: data.insuranceUpto,
          }
        } 
      })
    }

    else {
      alert("Invalid CaseId ! Please check ");
      toast.error("Invalid CaseId ! Please check ");
    }
  };

  const filteredCases = tableData.filter(caseItem =>{
    // caseItem.name.toLowerCase().includes(search.toLowerCase())
    const searchTerm = search.toLowerCase();
    return (
      caseItem.name.toLowerCase().includes(searchTerm) ||
      caseItem.licensePlate.toLowerCase().includes(searchTerm) ||
      caseItem.caseId.toLowerCase().includes(searchTerm)
    )
  });

  const paginatedCases = filteredCases.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);


  useEffect(() => {
    if (isDiagramReady && diagramData) {
      const timeoutId = setTimeout(() => {
        handleUploadCarDiagram(diagramData);
        setIsDiagramReady(false);
      }, 1000);
  
      return () => clearTimeout(timeoutId); // Cleanup timeout on component unmount
    }
  }, [isDiagramReady, diagramData]);


// function svgToPng(svgElement) {
//   return new Promise((resolve, reject) => {
//     const svgData = new XMLSerializer().serializeToString(svgElement);
//     const canvas = document.createElement('canvas');
//     const ctx = canvas.getContext('2d');

//     const img = new window.Image();
//     img.onload = () => {
//       canvas.width = img.width;
//       canvas.height = img.height;
//       ctx.drawImage(img, 0, 0);
//       canvas.toBlob(resolve, 'image/png');
//     };
//     img.onerror = (error) => reject(error);

//     const dataUrl = 'data:image/svg+xml;base64,' + btoa(svgData);
//     img.src = dataUrl;
//   });
// }

  function svgToPng(svgElement) {
    return new Promise((resolve, reject) => {
      const svgData = new XMLSerializer().serializeToString(svgElement);
      
      // Creating a temporary SVG element
      const tempSvg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
      tempSvg.innerHTML = svgData;
      document.body.appendChild(tempSvg);
      
      // Get the bounding box of the SVG content
      const bbox = tempSvg.getBBox(); 
  
      const canvas = document.createElement('canvas');
      canvas.width = bbox.width + 10*2;  // Set canvas width to bounding box width padding
      canvas.height = bbox.height + 10*2; // Set canvas height to bounding box height padding
      const ctx = canvas.getContext('2d');
  
      const img = new window.Image();
      img.onload = () => {
        ctx.drawImage(img, 10 - bbox.x, 10 - bbox.y); // Center the image on the canvas
        canvas.toBlob(resolve, 'image/png');
        document.body.removeChild(tempSvg);
      };
      img.onerror = (error) => {
        document.body.removeChild(tempSvg);
        reject(error);
      };
  
      const dataUrl = 'data:image/svg+xml;base64,' + btoa(svgData);
      img.src = dataUrl;
    });
  }

  const handleUploadCarDiagram = async (data) => {
    const carDiagramElement = document.getElementById("carDiagram");
  
    if (!carDiagramElement) {
      console.error("CarDiagram element not found");
      return;
    }
  
    try {
      const carDiagramBlob = await svgToPng(carDiagramElement);
      
      const carDiagramKey = `images/${data._id}/carDiagram_${Date.now()}.png`;
      const carDiagramUrl = await uploadFile(carDiagramKey, carDiagramBlob, "image/png");
  
      // console.log("CarDiagram accessible at:", carDiagramUrl);
      
      const response = await jwtAxios.put(`/api/case/${data._id}`, {
        carImgURL: carDiagramUrl
      });
  
      if (response.status !== 200) {
        throw new Error('Failed to update the database');
      }
  
      console.log("Database update result:", response.data);
      
    } catch (error) {
      console.error("Error uploading CarDiagram:", error);
    }
  };

  const generateImage = async (data) => {
    if (data.caseType === 'Car') {
      setDiagramData(data);
      setIsDiagramReady(true);
      handleUploadCarDiagram(data);

    } else if (data.caseType === 'Two Wheeler') {
      setDiagramData(data);
      setIsDiagramReady(true);
    } else if (data.caseType === 'Farm Equipment') {
      setDiagramData(data);
    } else if (data.caseType === 'Three Wheeler') {
      setDiagramData(data);
    } else if (data.caseType === 'Commercial Vehicle') {
      setDiagramData(data);
    } else if (data.caseType === 'Construction Equipment') {
      setDiagramData(data);
    } else {
      console.log('Unknown case type');
    }
    setTimeout(()=>{
      window.location.reload();
    },2000)
  }

  const handleDownloadReport = async (data) => {
    if (data.caseType === 'Car') {
      setTimeout(() => {
        generatePdf(data);
        toast.success('Report downloaded successfully');
      }, 2000);
      // generatePdf(data);
      // toast.success('Report downloaded successfully');
    } else if (data.caseType === 'Two Wheeler') {
      generatePdfTwoWheeler(data);
      toast.success('Report downloaded successfully');
    } else if (data.caseType === 'Farm Equipment') {
      generatePdfFarmEquipment(data);
    } else if (data.caseType === 'Three Wheeler') {
      generatePdfThreeWheeler(data);
    } else if (data.caseType === 'Commercial Vehicle') {
      generatePdfCommercialVehicle(data);
    } else if (data.caseType === 'Construction Equipment') {
      generatePdfConstructionEquipment(data);
    } else {
      toast.error('Unknown case type');
      console.log('Unknown case type');
    }
  }

  const handleDownloadReportCopy = async (data) => {
    setTimeout(() => {
      generatePdfCopy(data);
      // toast.success('Report downloaded successfully');
    }, 2000);
  }

  // console.log("PDFURL", generatePdf);
  return (
    <>
      <AppsContainer
        title={"Case Report"}
        fullView
      >
        <AppsHeader>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: 1,
            }}
          >
            <AppSearchBar
              iconPosition='right'
              overlap={false}
              onChange={onSearchCustomer}
              placeholder={messages['common.searchHere']}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                ml: 'auto',
              }}
            >
              <Button onClick={() => { console.log("helo") }}>Download CSV</Button>
              <Select
                labelId="demo-select-small-label"
                value={rowsPerPage}
                onChange={onRowsPerPageChange}
                variant="outlined"
                size='small'
                sx={{ margin: 2 }}
            >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={30}>30</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
              <Hidden smDown>
                <AppsPagination
                  rowsPerPage={rowsPerPage}
                  count={filteredCases.length}
                  page={page}
                  onPageChange={onPageChange}
                />
              </Hidden>
            </Box>
          </Box>
        </AppsHeader>

        <AppsContent
          sx={{
            paddingTop: 2.5,
            paddingBottom: 2.5,
          }}
        >
          <Table stickyHeader className='table'>
            <TableHead>
              <TableCell>Case Id</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Number</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Created Date</TableCell>
              <TableCell>Vehicle Name</TableCell>
              <TableCell>Color</TableCell>
              <TableCell>License Plate</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Download Annotated Video</TableCell>
              <TableCell>Complete Manually</TableCell>
              <TableCell>Download Report</TableCell>
              <TableCell>Generate Diagram</TableCell>
              <TableCell>Image Detection</TableCell>
              {/* <TableCell>Download Report Copy</TableCell> */}
            </TableHead>
            {loading ?     
            <Box>
              <CircularProgress />
            </Box>
            :
            <TableBody>
              {paginatedCases.map((data) => (
                <TableRow>
                  {/* {data.name !== "" && data.caseStatus === 'Completed' ? <> */}
                    <TableCell>{data.caseId}</TableCell>
                    <TableCell>{data.name}</TableCell>
                    <TableCell>{data.number}</TableCell>
                    <TableCell>{data.email}</TableCell>
                    <TableCell align='left'>
                        {dayjs(data.createdAt).format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell>{data.vehicleName}</TableCell>
                    <TableCell>{data.color}</TableCell>
                    <TableCell>{data.licensePlate}</TableCell>
                    <TableCell>{data.address}</TableCell>
                    <TableCell>{data.caseStatus}</TableCell>
                    <TableCell>
                      <IconButton style={{ color: videoAvailable[data._id] ? 'green' : 'gray' }} onClick={() => handleDownloadVideo(data._id)}>
                        <DownloadIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <Button
                        sx={{ display: 'block', minWidth: 100 }}
                        color='primary'
                        variant='contained'
                        type='button'
                        onClick={() => handleCompleteManually(data)}
                      >
                        Complete Manually
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button onClick={() => handleDownloadReport(data)}>
                        Download Report
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button 
                        variant='outlined' 
                        onClick={() => generateImage(data)}
                      >
                        Generate
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button
                        variant='outlined'
                        color="secondary"
                        onClick={() => fetchAiImages(data)}
                      >
                        Update Images
                      </Button>
                    </TableCell>

                    {/* <TableCell>
                      <Button onClick={() => handleDownloadReportCopy(data)}>
                        Download Report Copy
                      </Button>
                    </TableCell> */}
                  {/* </> : null} */}
                </TableRow>
              ))}
            </TableBody>
            }
          </Table>

          <div style={{ position: 'absolute', left: '-9999px', top: '-9999px' }}>
            <CarDiagram id="carDiagram" data={diagramData} />
          </div>
        </AppsContent>

        <Hidden smUp>
          <AppsPagination
            rowsPerPage={rowsPerPage}
            count={filteredCases.length}
            page={page}
            onPageChange={onPageChange}
          />
        </Hidden>
      </AppsContainer>

      <ContactDetail
        selectedContact={selectedContact}
        isShowDetail={isShowDetail}
        onShowDetail={onShowDetail}
        delete_fun={delete_fun} delete_name={"Decline"} edit_name={"Approve"} edit_fun={edit_fun}
      />

      <AppInfoView />

      <Dialog
        open={openConfirmDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this user ({userToDelete?.Name})?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <ToastContainer /> {/* To show pop up message */}
    </>
  );
};

export default CaseReport;
