import React, { useState } from "react";
import {
  Alert,
  Box,
  Button,
  Typography,
  TextField,
  Grid,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Card,
  CardContent,
  Divider,
  Paper,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { CreditCard } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';

const PaymentPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { paymentAmount } = location.state || {};
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  const [paymentMethod, setPaymentMethod] = useState("");

  // Change with prod url
  const baseURL = 'https://smartinspectai.justthinktech.in/smartinspectionai/v1'; // prod
  // const baseURL = 'http://localhost:8080/smartinspectionai/v1'; // local

  const savedFormData = JSON.parse(localStorage.getItem('formData'));
  const savedPaymentData = JSON.parse(localStorage.getItem('paymentData'));

  const handlePaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  const handleConfirmPayment = async() => {

    if (paymentMethod === "cod") {
      await submitFormData();
    } 
    else if (paymentMethod === "online" && success) {
        await submitFormData();
    }
  };

    // Replace with your actual Razorpay key
  const RAZORPAY_KEY = 'rzp_live_ObOu5LmHvTUi3i';
  // const RAZORPAY_KEY = 'rzp_test_ObOu5LmHvTUi3i'

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };

  const handleCardPayment = async () => {
    if (!paymentAmount || paymentAmount <= 0) {
      setError('Please enter a valid amount');
      return;
    }

    setLoading(true);
    setError('');

    try {
      const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');
      if (!res) {
        setError('Failed to load Razorpay SDK');
        return;
      }

      // Create order on your backend
      const orderData = await createOrder(paymentAmount);

      const options = {
        key: RAZORPAY_KEY,
        amount: orderData.amount, // Amount in paise
        currency: 'INR',
        name: 'Adsum Advisory',
        description: 'SmartInspection Form Payment',
        order_id: orderData.id,
        // method: 'card', // Restrict to card payment only
        handler: function (response) {
          handlePaymentSuccess(response);
        },
        modal: {
          ondismiss: function() {
            setLoading(false);
          }
        },
        prefill: {
          name: savedFormData.name,
          email: savedFormData.email,
          contact: savedFormData.mobNumber
        },
        notes: {
          payment_type: 'card'
        },
        theme: {
          color: '#1976d2',
          hide_topbar: false
        }
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();

    } catch (err) {
      setError('Payment failed: ' + err.message);
      setLoading(false);
    }
  };

  const createOrder = async (paymentAmount) => {
    try {
      const response = await fetch(`${baseURL}/payment-order`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          amount: paymentAmount * 100, // amount in rupees
          // payment_method: 'card'
        })
      });
      
      if (!response.ok) {
        throw new Error('Failed to create order');
      }
      
      return await response.json();
    } catch (err) {
      throw new Error('Failed to create order: ' + err.message);
    }
  };

  const handlePaymentSuccess = async (response) => {
    try {
      const verifyResponse = await fetch(`${baseURL}/verify-payment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
// "razorpay_payment_id":  "pay_PPQl7W060VS3kN",
// razorpay_order_id: "order_PPQkk6I948XvSZ",
// "razorpay_signature": "0d85ecf990540997f8a00a6773fa05c5e31990910d1bd7a656bbdfc1c7ba9d01"

        body: JSON.stringify({
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_order_id: response.razorpay_order_id,
          razorpay_signature: response.razorpay_signature
        })
      });

      const data = await verifyResponse.json();
      
      if (data.success) {
        setSuccess('Payment successful! Transaction ID: ' + response.razorpay_payment_id);
        // setAmount('');
        const paymentData = {
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_order_id: response.razorpay_order_id,
          razorpay_signature: response.razorpay_signature
        };
  
        localStorage.setItem('paymentData', JSON.stringify(paymentData));
      } else {
        setError('Payment verification failed');
      }
    } catch (err) {
      setError('Payment verification failed: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

    const submitFormData = async () => {
        try {
            
            if (!savedFormData) {
                toast.error('Form data not found in localStorage!');
                return;
            }

            const formDataWithPaymentMethod = {
                ...savedFormData,
                paymentMethod,
                ...savedPaymentData,
                // transactionId: response.razorpay_payment_id,
            };
            console.log(formDataWithPaymentMethod);

            const response = await axios.post(`${baseURL}/smartinspect-sheetapi`, formDataWithPaymentMethod);
            console.log('Form submitted successfully:', response.data);

            toast.success('Form submitted successfully!');

            await axios.post(`${baseURL}/send-email`,  { formData: formDataWithPaymentMethod } );
            // console.log('Email sent successfully');

            localStorage.removeItem('formData');
            localStorage.removeItem('paymentData');
            navigate('/form');

        } catch (error) {
            console.error('Error during submission:', error);
            toast.error('Error! Please try again later.');
        }
    };

console.log("sssss", success);

  return (
    <Box
      sx={{
        width: { xs: "100%", sm: "70%", md: "50%" },
        margin: "0 auto",
        padding: 5,
        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        borderRadius: 2,
        backgroundColor: "white",
      }}
    >
        <Button
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate('/smart-inspect')}
            sx={{ marginBottom: 2 }}
        >
            Back
        </Button>
      {/* Title */}
      <Typography variant="h4" fontWeight={600} gutterBottom align="center">
        Payment Options
      </Typography>

      <Typography variant="h6" gutterBottom align="center">
        You need to pay {" "}
        <span style={{ color: 'green', fontWeight: 'bolder' }}>
            ₹{paymentAmount || 0}
        </span>
        {" "} for the selected service.
      </Typography>

      <FormControl component="fieldset" margin="normal" fullWidth>
        <FormLabel component="legend">Select Payment Method</FormLabel>
        <RadioGroup value={paymentMethod} onChange={handlePaymentMethodChange}>
          <FormControlLabel
            value="cod"
            control={<Radio />}
            label="Cash on Delivery (COD)"
          />
          {paymentAmount > 0 && <FormControlLabel
            value="online"
            control={<Radio />}
            label="Credit/Debit Card"
          />}
        </RadioGroup>
      </FormControl>

      {paymentMethod === "online" && (
      <Box sx={{ p: 3 }}>
         <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
            <CreditCard sx={{ fontSize: 32, mr: 2, color: 'primary.main' }} />
            <Typography variant="h5">
              Payment
            </Typography>
          </Box>

        <Divider sx={{ mb: 3 }} />
        
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        
        {success && (
          <Alert severity="success" sx={{ mb: 2 }}>
            {success}
          </Alert>
        )}

        <Grid container spacing={3}>
          {!success && <Grid item xs={12}>
            <TextField
              fullWidth
              label="Amount (INR)"
              type="number"
              value={paymentAmount}
            //   onChange={(e) => setAmount(e.target.value)}
              disabled={loading}
              InputProps={{
                startAdornment: <Typography sx={{ mr: 1 }}>₹</Typography>,
              }}
            />
          </Grid>}

          <Grid item xs={12} textAlign='center'>
            {!success ? <Button
              variant="contained"
              color="primary"
              fullWidth
              size="large"
              onClick={handleCardPayment}
              disabled={loading}
              startIcon={<CreditCard />}
            >
              {loading ? 'Processing...' : `Pay ₹${paymentAmount || '0'}`}
            </Button> 
            : 
            <Button
              variant="contained"
              color="primary"
              onClick={handleConfirmPayment}
              sx={{ padding: "10px 30px" }}
            >
                Submit the form
            </Button>
            }
          </Grid>
        </Grid>

        <Box sx={{ mt: 3 }}>
          <Typography variant="caption" color="text.secondary" align="center" display="block">
            Secure payments powered by Razorpay
          </Typography>
        </Box>
      </Box>
      )}

      {paymentMethod === 'cod' &&
        <Box sx={{ marginTop: 3, textAlign: "center" }}>
            <Button
                variant="contained"
                color="primary"
                onClick={handleConfirmPayment}
                sx={{ padding: "10px 30px" }}
            >
                Confirm Payment
            </Button>
        </Box>
      }

      <ToastContainer />
    </Box>
  );
};

export default PaymentPage;
