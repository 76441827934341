import React, { useEffect, useState, useRef } from 'react';
import { Box, Button, Container, Typography, Alert, Grid, Divider, Modal } from '@mui/material';
import AppsContainer from '@crema/components/AppsContainer';
import { useIntl } from 'react-intl';
import { useAppDispatch } from '../../../toolkit/hooks';
import { useLocation, useParams } from "react-router-dom";
import jwtAxios, { setAuthToken } from '@crema/services/auth/jwt-auth';
import uploadFile from 's3/s3.upload';
import Confetti from 'react-confetti';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import useVahanVehicleDetails from '../Admin/CustomHooks/useVahanVehicleDetails';
import frontLogo from './Images/Front.svg';
import frontUnderCarriageLogo from './Images/Front-Undercarriage.svg';
import windshieldExteriorLogo from './Images/Windshield-exterior.svg';
import frontRightLogo from './Images/Front-Right.svg';
import rightLogo from './Images/Right.svg';
import backRightLogo from './Images/Back-Right.svg';
import backLogo from './Images/Back.svg';
import dickyLogo from './Images/Dicky.svg';
import rearUnderCarriageLogo from './Images/Rear-Undercarriage.svg';
import backLeftLogo from './Images/Back-Left.svg';
import leftLogo from './Images/Left.svg';
import frontLeftLogo from './Images/front-Left.svg'
import windshieldInteriorLogo from './Images/Windshield-Interior.svg';
import odometerLogo from './Images/Odometer&RPM.svg';
import chassisLogo from './Images/Chassis.svg';
import rcLogo from './Images/RC.svg';
import { useTheme } from '@mui/material/styles';
import captureSound from '../../../../src/assets/sounds/captured-sound.wav';
import rotateGif from '../../../../src/assets/gif/rotate-your-screen-animation-_black_.gif';
import SignatureCanvas from 'react-signature-canvas';

const steps = [
  "FRONT",
  "FRONT UNDERCARRIAGE",
  "WINDSHIELD EXTERIOR",
  "FRONT RIGHT",
  "RIGHT",
  "BACK RIGHT",
  "BACK",
  "DICKY",
  "REAR UNDERCARRIAGE",
  "BACK LEFT",
  "LEFT",
  "FRONT LEFT",
  "WINDSHIELD INTERIOR",
  "ODOMETER & RPM",
  "CAR ACCESSORIES",
  "ENGINE",
  "CHASSIS",
  "RC",
  "SELFIE",
  "CUSTOMER SIGN",
  "EXTRA (Optional 1)",
  "EXTRA (Optional 2)",
  "EXTRA (Optional 3)",
  "EXTRA (Optional 4)",
  "EXTRA (Optional 5)",
];

const bodyPartSteps = [
  "front",
  "frontUndercarriage",
  "windscreenExterior",
  "frontRight",
  "right",
  "backRight",
  "back",
  "dicky",
  "rearUndercarriage",
  "backLeft",
  "left",
  "frontLeft",
  "windscreenInterior",
  "odometerRpm",
  "carAccessories",
  "engine",
  "chassis",
  "rc",
  "selfie",
  "customerSign",
  "extraOptional1",
  "extraOptional2",
  "extraOptional3",
  "extraOptional4",
  "extraOptional5",
];

// Add an array of images corresponding to each step
const stepImages = [
  frontLogo,
  frontUnderCarriageLogo,
  windshieldExteriorLogo,
  frontRightLogo,
  rightLogo,
  backRightLogo,
  backLogo,
  dickyLogo,
  rearUnderCarriageLogo,
  backLeftLogo,
  leftLogo,
  frontLeftLogo,
  windshieldInteriorLogo,
  odometerLogo,
  '', // Car Accessories
  '', // Engine
  chassisLogo,
  rcLogo,
  '', // Selfie
  '', // customer Sign
  '', // Optional 1
  '', // Optional 2
  '', // Optional 3
  '', // Optional 4
  '', // Optional 5
];

const SelfEvaluation = () => {
  const navigate = useNavigate(); // Initialize navigate hook
  const { id } = useParams();
  const { messages } = useIntl();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [cameraAccess, setCameraAccess] = useState<null | boolean>(null);
  const [currentStep, setCurrentStep] = useState(-1); // Track current step; -1 indicates vehicle details page
  const [vehicleDetails, setVehicleDetails] = useState<any>(null); // State to store fetched vehicle details
  const [latLng, setLatLng] = useState<{ lat: number; lng: number } | null>(null); // State to store coordinates
  const [address, setAddress] = useState<string>(''); // State to store address
  const [isMobile, setIsMobile] = useState<boolean>(false); // State to check if device is mobile
  const videoRef = useRef<HTMLVideoElement | null>(null); // Ref for the video element
  const canvasRef = useRef<HTMLCanvasElement | null>(null); // Ref for the canvas element
  const capturedImages = useRef<HTMLCanvasElement[]>([]); // To store captured images
  const [timer, setTimer] = useState(420); // Timer in seconds (7 minutes)
  const [showOrientationAlert, setShowOrientationAlert] = useState(false);
  const [isPortrait, setIsPortrait] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const signatureRef = useRef<SignatureCanvas | null>(null);

  const theme = useTheme();

  useEffect(() => {
    // Initialize the audio reference
    audioRef.current = new Audio(captureSound);
  }, []);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isMobileDevice = /android|iPad|iPhone|iPod/.test(userAgent.toLowerCase());
    // console.log("isMobileDevice", isMobileDevice);
    setIsMobile(isMobileDevice);
  }, []);

  // Function to request camera access
  const requestCameraAccess = async () => {
      
    try {
      // const isMobile = /android|iPad|iPhone|iPod/.test(navigator.userAgent.toLowerCase());
      const constraints = {
        // video: true,
        video: {
          // facingMode: {exact: 'user'}, // To check in desktop
          facingMode: { exact: 'environment' }, // Use back camera on mobile, front camera otherwise
          aspectRatio: { ideal: 16 / 9 },
          width: { ideal: 1280 },
          height: { ideal: 720 }
        },
        audio: false,
      };
  
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      setCameraAccess(true);
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      }
    } catch (error) {
      console.error('Error accessing camera:', error);
  
      if (error.name === 'NotAllowedError') {
        alert('Camera access denied. Please enable camera permissions in your browser settings.');
      } else if (error.name === 'NotFoundError') {
        alert('No camera found. Please ensure your device has a camera.');
      } else if (error.name === 'OverconstrainedError') {
        alert('Back camera not available. Please ensure your device supports this feature.');
      } else {
        alert('An unknown error occurred. Please check your browser settings.');
      }
  
      setCameraAccess(false);
    }
  };

  // Handle orientation change
  useEffect(() => {
    const handleOrientationChange = () => {
      const isLandscape = window.matchMedia("(orientation: landscape)").matches;
      setIsPortrait(!isLandscape);
      if (videoRef.current) {
        videoRef.current.style.transform = isLandscape ? "rotate(0deg)" : null;

      }
      if(isLandscape){
        setOpenModal(false);
      }
      else {
        setOpenModal(true);
      }
    };

    // Initial check
    handleOrientationChange();
    window.addEventListener('orientationchange', handleOrientationChange);
    window.addEventListener('resize', handleOrientationChange);

    const intervalId = setInterval(() => {
      const isLandscape = window.matchMedia("(orientation: landscape)").matches;
      if (!isLandscape) {
        setOpenModal(true);
      }
    }, 3000);

    return () => {
      clearInterval(intervalId);
      window.removeEventListener('orientationchange', handleOrientationChange);
      window.removeEventListener('resize', handleOrientationChange);
    };
  }, []);
  

  // Function to fetch the address using reverse geocoding
  const fetchAddress = async (lat: number, lng: number) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyCJi9IR38yVxFmeir2yV6LZiZicrsZyIak`
      );
      const data = await response.json();
      if (data.results && data.results.length > 0) {
        setAddress(data.results[0].formatted_address); // Set the fetched address
      }
    } catch (error) {
      console.error('Error fetching address:', error);
    }
  };

  // Function to get user's coordinates
  const getCoordinates = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLatLng({ lat: latitude, lng: longitude }); // Set coordinates
          fetchAddress(latitude, longitude); // Fetch address using coordinates
        },
        (error) => {
          console.error('Error getting location:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };

  const startSelfEvaluation = () => {
    setCurrentStep(0); // Start the self-evaluation steps
    requestCameraAccess(); // Request camera access immediately
    setTimer(420); // Reset timer to 7 minutes
    getCoordinates(); // Get user's location
  };

  // Function to draw and wrap text
  function drawText(text: any, x: any, y: any, maxWidth: any, context: any) {
    const words = text.split(' ');
    let line = '';
    let lineHeight = parseInt(context.font, 10) + 5; // Adjust line height

    for (let n = 0; n < words.length; n++) {
      const testLine = line + words[n] + ' ';
      const metrics = context.measureText(testLine);
      const testWidth = metrics.width;

      if (testWidth > maxWidth && n > 0) {
          context.fillText(line, x, y);
          line = words[n] + ' ';
          y += lineHeight; // Move to next line
      } else {
          line = testLine;
      }
    }
    context.fillText(line, x, y); // Draw the last line
  }

  const captureImage = async () => {
    if (videoRef.current && canvasRef.current) {
      const context = canvasRef.current.getContext('2d');
      if (context) {
        const videoWidth = videoRef.current.videoWidth;
        const videoHeight = videoRef.current.videoHeight;
        
        // Adjust canvas size based on orientation if needed
        const isPortrait = videoHeight > videoWidth;
        canvasRef.current.width = isPortrait ? videoHeight : videoWidth;
        canvasRef.current.height = isPortrait ? videoWidth : videoHeight;
        // canvasRef.current.width = videoRef.current.videoWidth;
        // canvasRef.current.height = videoRef.current.videoHeight;
        context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);

        const currentDateTime = new Date().toLocaleString();
        const padding = 15; // Set padding from the edges
        const maxWidth = canvasRef.current.width - padding;

        // Set up the font and color
        context.font = '16px Arial'; // Set font size and type
        context.fillStyle = '#7000e4'; // Set text color for better contrast
        context.textAlign = 'right'; // Align text to the right

        // Draw date and time
        drawText(currentDateTime, canvasRef.current.width - padding, canvasRef.current.height - 50, maxWidth, context);

        // Draw address below
        drawText(address, canvasRef.current.width - padding, canvasRef.current.height - 30, maxWidth, context);

        // Play the capture sound
        if (audioRef.current) {
          audioRef.current.currentTime = 0;
          audioRef.current.play();
        }

        const dataUrl = canvasRef.current.toDataURL('image/jpeg'); // Convert the canvas to a data URL
        const blob = dataURLtoBlob(dataUrl); // Convert the data URL to a Blob

        const bodyPart = bodyPartSteps[currentStep];
        const key = `images/${id}/${bodyPart}_${Date.now()}_.jpg`;

        try {
          const fileUrl = await uploadFile(key, blob, 'image/jpeg');
          capturedImages.current[currentStep] = fileUrl;
          await updateVehicleImages();
          console.log("Image uploaded to:", fileUrl);
        } catch (error) {
          console.error("Error uploading image:", error);
        }

        // Move to the next step or complete
        if (currentStep < steps.length - 1) {
          setCurrentStep(currentStep + 1);
        } else {
          setCurrentStep(steps.length); // Mark as completed
          navigate(`/video-recording/${id}`); // Navigate to video recording page
        }
      }
    }
  };

  const dataURLtoBlob = (dataUrl: string) => {
    const byteString = atob(dataUrl.split(',')[1]);
    const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const handleSignatureSave = async () => {
    const signatureDataUrl = signatureRef.current?.getTrimmedCanvas().toDataURL('image/jpeg');
    if (signatureDataUrl) {
      const blob = dataURLtoBlob(signatureDataUrl);
      const key = `images/${id}/${Date.now()}_.jpg`;
      try {
        const fileUrl = await uploadFile(key, blob, 'image/jpeg');
        capturedImages.current[currentStep] = fileUrl;
        await updateVehicleImages();
        // console.log("Signature uploaded to:", fileUrl);
        
        if (currentStep < steps.length - 1) {
          setCurrentStep(currentStep + 1);
        }
      } catch (error) {
        console.error("Error uploading signature:", error);
      }
    }
  };
  

  const updateVehicleImages = async () => {
    try {
      const formDataWithStatus = {
        images: capturedImages.current,
        // bodyType:  vehicleData?.result?.bodyType,
        // cusSign: currentStep === steps.findIndex(step => step === "CUSTOMER SIGN") ? capturedImages.current[currentStep] : "" ,
      };

      if(steps[currentStep] ===  "CUSTOMER SIGN"){
        formDataWithStatus.cusSign = capturedImages.current[currentStep]
      }

      await jwtAxios.put(`/api/case/${id}`, formDataWithStatus);
      console.log('Update successful');
    } catch (error) {
      console.error('Error updating data:', error);
    }
  };

  useEffect(() => {
    if (timer > 0 && currentStep >= 0 && currentStep < steps.length) {
      const interval = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);

      return () => clearInterval(interval);
    } else if (timer === 0) {
      setCurrentStep(-1);
      setCameraAccess(null);
      console.log('Timer reset due to timeout');
    }
  }, [timer, currentStep]);

  useEffect(() => {
    if (cameraAccess && videoRef.current && videoRef.current.srcObject === null) {
      navigator.mediaDevices.getUserMedia({ 
        // to check on the desktop uncomment this video: true
        // video: true 
        video: { 
          facingMode: { exact: "environment" },
          aspectRatio: { ideal: 16 / 9 },
          width: { ideal: 1280 },
          height: { ideal: 720 }
       } 
      }) // Request back camera again
        .then(stream => {
          videoRef.current!.srcObject = stream;
          videoRef.current!.play();
        })
        .catch(error => {
          console.error('Error accessing camera:', error);
        });
    }
  }, [cameraAccess]);

  // Update useEffect for camera access
  useEffect(() => {
    if (cameraAccess && (currentStep >= 0 && currentStep < steps.length)) {
      requestCameraAccess(); // Request camera access when it's enabled and step is valid
    }
  }, [cameraAccess, currentStep]);

  useEffect(() => {
    const fetchLogin = async () => {
      const email = 'dev@gmail.com';
      const password = 'dev123';
      
      try {
        const { data } = await jwtAxios.post('auth/webuser/login', { email, password });

        localStorage.setItem('token', data.accessToken);
      } catch (error) {
        console.error("Error during auto login:", error);
      }

      try {
        const response = await jwtAxios.get(`api/case/${id}`);
        setVehicleDetails(response.data);
      } catch (error) {
        console.error("Error fetching vehicle details:", error);
      }
    };

    fetchLogin();
  }, []);

  // Fetching the data from Vahan API
  const signzyID = 'PAYTMSZY';

  const {vehicleData, error, loading} = useVahanVehicleDetails(vehicleDetails?.licensePlate, signzyID)
  // console.log(vehicleData?.result)

  if (loading) return <p>Loading...</p>;

  return (
    <>
      {/* <AppsContainer title={"Self Complete Form"} 
        sxStyle={{
          maxWidth: {
            xs: '100%', // 0px and up
            sm: '100%',
            md: theme.breakpoints.values.md,
            lg: theme.breakpoints.values.lg,
          },
        }}
      > */}
      {/* <Typography variant='h4'>Self Complete Form</Typography> */}
        <Container
          // maxWidth='sm'
          sx={{
            maxWidth: {
              xs: '100%', // 0px and up
              sm: theme.breakpoints.values.sm,
              md: theme.breakpoints.values.sm,
              lg: theme.breakpoints.values.md,
            },
          }}
        >
          { !vehicleData?.result?.regNo && (
            <Alert severity="warning">Vehicle Number is not registered with Vahan</Alert>
          )}
          {/* Warning message for mobile device usage */}
          {!isMobile && !/iPhone/.test(navigator.userAgent) && (
            <Alert severity="warning" sx={{ mb: 3 }}>
              Please use a mobile device for this evaluation.
            </Alert>
          )}

          {showOrientationAlert && (
            <Alert severity="info" sx={{ position: 'fixed', top: 16, right: 16, zIndex: 999 }}>
              For the best experience, please rotate your device to landscape mode.
            </Alert>
          )}

          {currentStep === -1 && (
            <>
              <Box
                sx={{
                  mx: 'auto',
                  mt: 2,
                  p: 3,
                  backgroundColor: '#fff',
                  borderRadius: 2,
                  // boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                  textAlign: 'start',
                  // border: 'solid 1px green',
                }}
              >
                {/* Back Button and Title */}
                <Grid container >
                  <Typography variant="h1" sx={{ fontWeight: 'bold' }}>
                    {vehicleData?.result?.owner || vehicleDetails?.name}
                  </Typography>
                </Grid>

                {/* Email and Phone */}
                <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                  {vehicleDetails?.email || 'Loading...'} | {vehicleData?.result?.mobileNumber || vehicleDetails?.number}
                </Typography>

                <Divider sx={{ my: 3 }} />

                {/* Vehicle Information */}
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="body2" color="textSecondary">
                      Vehicle Name
                    </Typography>
                    <Typography variant="body1" fontWeight="bold">
                      {vehicleData?.result?.mappings?.variantIds[0]?.make || vehicleDetails?.vehicleName}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" color="textSecondary">
                      Color
                    </Typography>
                    <Typography variant="body1" fontWeight="bold">
                      {vehicleData?.result?.vehicleColour || vehicleDetails?.color}
                    </Typography>
                  </Grid>
                </Grid>

                {/* License Plate */}
                <Box sx={{ mt: 2 }}>
                  <Typography variant="body2" color="textSecondary">
                    License Plate
                  </Typography>
                  <Typography variant="body1" fontWeight="bold">
                    {vehicleData?.result?.regNo || vehicleDetails?.licensePlate}
                  </Typography>
                </Box>

                {/* Address */}
                <Box sx={{ mt: 2 }}>
                  <Typography variant="body2" color="textSecondary">
                    Address
                  </Typography>
                  <Typography variant="body1" fontWeight="bold">
                    {vehicleData?.result?.presentAddress || vehicleDetails?.address}
                  </Typography>
                </Box>

                {/* Button */}
                {(vehicleDetails?.caseStatus !== 'Completed')  ? (<Button
                  variant="contained"
                  fullWidth
                  sx={{
                    mt: 5,
                    py: 1.5,
                    backgroundColor: '#E6DBF8',
                    color: '#7000e4',
                    fontWeight: 'bold',
                    borderRadius: 10,
                    '&:hover': {
                      backgroundColor: '#D0BCF5',
                    },
                  }}
                  onClick={startSelfEvaluation}
                >
                  Start Self Evaluation
                </Button>) : (
                  <Alert variant="filled" severity="success" sx={{ mt: 3}}>It's Already Completed</Alert>
                )}
              </Box>
            </>
          )}

          {cameraAccess === false && (
            <Typography sx={{ color: 'red', mt: 2, mb: 2 }}>
              Camera permission denied. Please allow camera access in your browser settings.
            </Typography>
          )}

          {currentStep === steps.findIndex(step => step === "CUSTOMER SIGN") ? (
            <Box sx={{ mt: 3, display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
              <Typography variant="h6">Please sign below:</Typography>
              <Box border='solid 3px orange' borderRadius="5px">
                <SignatureCanvas
                  ref={signatureRef}
                  border= 'solid 2px red'
                  backgroundColor="white"
                  penColor="#7000e4"
                  canvasProps={{ width: 350, height: 120, className: 'sigCanvas' }}
                />
              </Box>
              <Box display='flex' justifyContent='space-between'>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ mt: 2 }}
                  onClick={handleSignatureSave}
                >
                  Save Signature
                </Button>
                <Button
                  variant="outlined"
                  sx={{ mt: 2 }}
                  onClick={() => signatureRef.current?.clear()}
                >
                  Clear Signature
                </Button>
              </Box>
            </Box>
          ) : (
            cameraAccess === true && currentStep >= 0 && currentStep < steps.length && (
              <Box>
                <Typography variant="h5" fontSize='20px' fontWeight='600' sx={{ mb: 3 }}>
                  Step {currentStep + 1}: {steps[currentStep]}
                </Typography>
                <Box
                  sx={{
                    width: '100%',
                    // maxWidth: '350px',
                    borderRadius: '12px',
                    overflow: 'hidden',
                    margin: '0 auto',
                    mt: 2,
                    position: 'relative',
                  }}
                >
                  <video
                    ref={videoRef}
                    style={{ width: '100%',
                    maxHeight: '370px', height: 'auto', objectFit: 'cover' }}
                    autoPlay
                    playsInline
                    muted
                  />
  
                  <img
                      src={stepImages[currentStep]}
                      alt={`Step ${currentStep + 1}`}
                      style={{
                        position: 'absolute',
                        top: '10px',
                        left: '10px',
                        width: 'auto',
                        height: '75px',
                        objectFit: 'cover',
                        opacity: 1, // Adjust opacity to see through
                        borderRadius: '12px',
                      }}
                    />
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      position: 'absolute',
                      bottom: '15%', // Adjust this value for more spacing
                      left: '50%',
                      transform: 'translateX(-50%)', // Center the button horizontally
                      fontSize: '14px',
                      mb: 2,
                    }}
                    onClick={captureImage}
                  >
                    Capture Image for "{steps[currentStep]}"
                  </Button>
  
                  <Typography
                    color="Highlight"
                    sx={{ mt: 2, fontSize: '16px', fontWeight: 600 }}
                  >
                    Captured Images {currentStep}
                  </Typography>
  
                  {/* Add a Skip button for the EXTRA (Optional) step */}
                  {steps[currentStep] === "EXTRA (Optional 1)" && (
                    <Button
                      variant="outlined"
                      color="secondary"
                      sx={{ mt: 2, fontSize: '16px', ml: 2 }}
                      onClick={() => {
                        if (currentStep < steps.length - 5) {
                          setCurrentStep(currentStep + 5);
                        } else {
                          setCurrentStep(steps.length);
                          navigate(`/video-recording/${id}`);
                        }
                      }}
                    >
                      Skip All (Optional)
                    </Button>
                  )}
                </Box>
                <Typography variant="h6" sx={{ mt: 3 }}>
                  Time remaining: {Math.floor(timer / 60)}:{timer % 60 < 10 ? '0' : ''}{timer % 60}
                </Typography>
              </Box>
            )
          )}
          

          {cameraAccess === true && currentStep >= steps.length && (
            <>
              <Typography variant="h6" sx={{ mt: 3, color: 'green' }}>
                All steps completed! Verification successful.
              </Typography>
              <Confetti />
            </>
          )}

          <canvas ref={canvasRef} style={{ display: 'none' }} />
        </Container>
      {/* </AppsContainer> */}

      <Modal
        open={openModal}
        onClose={() => setOpenModal(true)}
        aria-labelledby="rotate-device-modal"
        aria-describedby="rotate-device-description"
      >
        <Box sx={{ 
          bgcolor: 'white', 
          p: 4, 
          borderRadius: 2, 
          position: 'absolute', 
          top: '50%', 
          left: '50%', 
          transform: 'translate(-50%, -50%)', 
          boxShadow: 3 
        }}>
          <Typography id="rotate-device-title" variant="h4" component="h2">
            Please Rotate Your Device
          </Typography>
          <Typography id="rotate-device-description" sx={{ mt: 2 }}>
            For the best experience, you must rotate your device to landscape mode to continue.
          </Typography>
          <img
            src={rotateGif}
            alt="Rotate Phone"
            style={{ width: '200px', height: 'auto' }}
          />
          <Button 
            variant="contained" 
            onClick={() => setOpenModal(true)}
            sx={{ mt: 3 }}
            disabled // Disable button while in portrait mode
          >
            Okay
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default SelfEvaluation;
